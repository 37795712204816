import React, { useState } from "react";
import { Badge, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import Icon from "@mdi/react";
import { mdiFullscreen, mdiFullscreenExit, mdiLogout } from "@mdi/js";

type HeaderProps = {
	authentication?: boolean;
};

export default function Header(props: HeaderProps) {
	const { user, handleLogout } = useAuth();
	const navigate = useNavigate();

	const [fullScreen, setFullScreen] = useState(false);

	async function handleFullscreen() {
		if (fullScreen) {
			document.exitFullscreen();
			setFullScreen(false);
		} else {
			document.documentElement.requestFullscreen();
			setFullScreen(true);
		}
	}

	async function handleSair() {
		handleLogout();
		navigate("/");
	}

	return (
		<div className={`d-flex justify-content-between py-2 px-3 position-fixed`} style={{ zIndex: 100, background: "rgba(31, 37, 60, 0.8)", backdropFilter: "blur(5px)" }}>
			<Link to={"/"} className="d-flex align-items-center gap-2 text-decoration-none">
				{process.env.REACT_APP_TENANT === "BMRS" && <Image fluid src={process.env.PUBLIC_URL + "/bm.png"} style={{ height: 40 }} />}
				<Image fluid src={process.env.PUBLIC_URL + "/logo_vigilante.png"} style={{ height: 40 }} />
				<Badge text="white" bg="air-purple">
					LIVE
				</Badge>
			</Link>
			<div className="d-flex align-items-center gap-3 text-white fw-light">
				{props.authentication !== false && (
					<div>
						Seja bem-vindo, <span className="fw-medium">{user?.nome}</span>
					</div>
				)}
				<Button variant={"outline-light"} className="d-flex align-items-center gap-1" size="sm" onClick={handleFullscreen} style={{ height: 32 }}>
					<Icon path={fullScreen ? mdiFullscreenExit : mdiFullscreen} size={1} /> Tela
				</Button>
				{props.authentication !== false && (
					<Button variant={"outline-light"} className="d-flex align-items-center gap-1" size="sm" onClick={handleSair} style={{ height: 32 }}>
						<Icon path={mdiLogout} size={0.8} /> Sair
					</Button>
				)}
			</div>
		</div>
	);
}
