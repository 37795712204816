import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { mdiBroadcastOff } from "@mdi/js";

import DashboardCard from "../../components/DashboardCard";
import VideoJS from "../../components/VideoJS";
import Layout from "../../components/Layout";

export default function Live() {
	const containerRef = useRef<HTMLDivElement>(null);

	const { url = "" } = useParams();
	const [onEnded, setOnEnded] = useState(false);

	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: false,
			responsive: true,
			fill: true,
			muted: true,
			sources: atob(url),
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [url]);

	return (
		<Layout authentication={false}>
			<div
				className="position-absolute"
				style={{
					inset: 0,
					height: "100%",
					backgroundImage: `url(${process.env.PUBLIC_URL}/login_background.webp)`,
					backgroundSize: "cover",
					zIndex: -1,
				}}
			/>
			<div className="d-flex justify-content-center p-2 w-100" style={{ height: "calc(100dvh - 56px)", marginTop: 56 }}>
				{onEnded === false && (
					<div ref={containerRef} className="position-relative w-100" style={{ height: "100%" }}>
						<div className={`d-inline-block position-absolute rounded-3 shadow overflow-hidden`} style={{ width: "100%", height: "100%", zIndex: 1 }}>
							<VideoJS
								options={videoJsOption}
								className="bg-dark"
								onEnded={() => {
									setOnEnded(true);
								}}
								style={{
									width: "100%",
									height: "100%",
								}}
							/>
						</div>
					</div>
				)}
				{onEnded === true && (
					<div className="d-flex align-items-center justify-content-center">
						<DashboardCard icon={mdiBroadcastOff} title="Live" value={"Transmissão encerrada"} />
					</div>
				)}
			</div>
		</Layout>
	);
}
