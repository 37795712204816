import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";
import Body from "../Body";

type LayoutProps = {
	children: ReactNode;
	authentication?: boolean;
};

export default function Layout({ children, authentication }: LayoutProps) {
	return (
		<Container fluid className="layout" style={{ minHeight: "100dvh" }}>
			<Body>
				<Header authentication={authentication} />
				{children}
			</Body>
		</Container>
	);
}
