import { useEffect, useRef } from "react";
import videojs from "video.js";

import "video.js/dist/video-js.css";

export const VideoJS = ({ options, onEnded, onReady, ...rest }: any) => {
	const videoRef = useRef<any>(null);
	const playerRef = useRef<any>(null);

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js");
			videoElement.classList.add("vjs-big-play-centered");
			videoRef.current.appendChild(videoElement);

			const player = (playerRef.current = videojs(videoElement, options, () => {
				onReady && onReady(player);
			}));
			player.on("pause", handlePauseOrWaiting);
			player.on("waiting", handlePauseOrWaiting);
			player.on("ended", handleEnded);
		} else {
			const player = playerRef.current;
			player.autoplay(options.autoplay);
			player.src(options.sources);
			player.on("pause", handlePauseOrWaiting);
			player.on("waiting", handlePauseOrWaiting);
			player.on("ended", handleEnded);
		}
		// eslint-disable-next-line
	}, [options, videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
		// eslint-disable-next-line
	}, [playerRef]);

	function handlePauseOrWaiting() {
		setTimeout(() => {
			const player = playerRef.current;
			if (player && player.paused()) {
				player.play();
			}
		}, 1000);
	}

	function handleEnded() {
		onEnded && onEnded();
	}

	return (
		<div data-vjs-player {...rest}>
			<div ref={videoRef} {...rest} />
		</div>
	);
};

export default VideoJS;

// import { useEffect, useRef } from "react";
// import videojs from "video.js";
// import Hls from "hls.js";

// import "video.js/dist/video-js.css";

// export const VideoJS = ({ options, onReady, ...rest }: any) => {
// 	const videoRef = useRef<any>(null);
// 	const playerRef = useRef<any>(null);
// 	const hlsRef = useRef<any>(null);

// 	useEffect(() => {
// 		const videoElement = videoRef.current;
// 		videoElement.addEventListener("pause", handlePauseOrWaiting);

// 		if (Hls.isSupported()) {
// 			// HLS PLAYER
// 			const hls = (hlsRef.current = new Hls({
// 				initialLiveManifestSize: 1, // Quantidade inicial de segmentos a serem carregados (para reduzir o atraso)
// 				maxLiveSyncPlaybackRate: 1.5, // Tenta ajustar a velocidade para manter a sincronia
// 				liveSyncDuration: 3, // Latência de sincronização para transmissão ao vivo
// 				liveMaxLatencyDuration: 5, // Latência máxima permitida antes de tentar recuperação
// 				liveDurationInfinity: true, // Garante que o vídeo seja tratado como uma transmissão ao vivo infinita
// 				maxBufferSize: 60 * 1000 * 1000, // Tamanho máximo do buffer (em bytes)
// 				maxBufferLength: 60, // Segundos máximos de vídeo a ser armazenado no buffer
// 				maxBufferHole: 2, // Tolerância máxima de "buraco" no buffer, antes de interromper a reprodução
// 			}));

// 			hls.loadSource(options.sources); // Coloque o link da sua live HLS aqui
// 			hls.attachMedia(videoElement);
// 			hls.on(Hls.Events.MANIFEST_PARSED, () => {
// 				videoElement.play();
// 			});
// 		} else {
// 			// VIDEO JS PLAYER
// 			const player = (playerRef.current = videojs(videoElement, options, () => {
// 				onReady && onReady(player);
// 			}));
// 		}

// 		// eslint-disable-next-line
// 	}, [options]);

// 	useEffect(() => {
// 		const video = videoRef.current;
// 		const player = playerRef.current;
// 		const hls = hlsRef.current;

// 		return () => {
// 			video.removeEventListener("pause", handlePauseOrWaiting);

// 			if (player && !player.isDisposed()) {
// 				player.dispose();
// 				playerRef.current = null;
// 			}
// 			if (hls) {
// 				hls.destroy();
// 				hls.current = null;
// 			}
// 		};
// 		// eslint-disable-next-line
// 	}, [playerRef]);

// 	function handlePauseOrWaiting() {
// 		const video = videoRef.current;
// 		setTimeout(() => {
// 			video.play().catch((err: any) => {
// 				console.error("Erro ao tentar dar play no vídeo:", err);
// 			});
// 		}, 1000);
// 	}

// 	return (
// 		<div data-vjs-player {...rest}>
// 			<video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered vjs-fill vjs-tech" {...options} />
// 		</div>
// 	);
// };

// export default VideoJS;
